/**
 * Removes the specified string from the given URL.
 * @param {string} url - The original URL.
 * @param {string} stringToRemove - The string that needs to be removed from the URL.
 * @returns {string} - The modified URL with the specified string removed.
 */
export function removeStringFromUrl(
  url: string,
  stringToRemove: string
): string {
  if (!url) {
    return url;
  }

  // Split the URL by '/'
  const urlParts: string[] = url.split("/");

  // Filter out any part that contains the specified string
  const filteredParts: string[] = urlParts.filter(
    (part: string) => !part.includes(stringToRemove)
  );

  // Reconstruct the URL by joining the filtered parts with '/'
  return filteredParts.join("/");
}
